
  import Vue from 'vue'
  import GraphDataProvider from "@/models/GraphDataProvider"
  import Constants from "@/models/Constants"
  import API from "@/models/API"
  import cytoscape from 'cytoscape';
  export default Vue.extend({
    name: 'Home',
    components: {
    },
    data() {
      return {
        showMenu: true,
        searching: false,
        tokens: [],
        rawTokens: [],
        tokenLookupTable: new Map<string, any>(),
        selectedAddress: '',
        elements: [],
        clusterElements: [],
        apiResultCount: 0,
        api: new API(),
        searchCount: 0,
        searchedQueries: [],
        minimumConnections: 1,
        connectionThreshold: 1,
        maxConnections: 0,
        maxTotalSum: 0,
        totalSumThreshold: 0,
        minimumTotalSum: 0,
        selectedFocus: Constants.HybridFocus,
        focusItems: [Constants.HybridFocus, Constants.RelationshipFocus, Constants.TransactionFocus],
        sheet: false,
        finetuneTotalSumThreshold: false,
        selectedLayout: "Cose",
        availableLayouts: [ {name: "Cose"},
                            {name: "Concentric"},
                            {name: "Grid"},
                            {name: "Circle"},
                            {name: "Breadthfirst"},
        ],
        currentToken: []
      }
    },
    methods: {
      async searchFromScratch() {
        this.searching = true
        this.maxConnections = 0
        this.maxTotalSum = 0
        this.connectionThreshold = 1
        this.totalSumThreshold = 0
        this.search()
      },
      async fetchTokenInfo() {
        this.selectedAddress = this.selectedAddress.toLowerCase()
        let tokenInfo = await this.api.getTokenInfo(this.selectedAddress)
        this.tokenLookupTable.set(tokenInfo['address'].toLowerCase(), tokenInfo)
        this.tokens.push(tokenInfo)
        return tokenInfo
      },
      async search() {
        this.selectedAddress = this.selectedAddress.toLowerCase()
        if(this.selectedAddress.length == 42) {
          this.currentToken = await this.fetchTokenInfo()

          // Update URL
          this.$router.replace({ query: { address: this.selectedAddress } })

          // Start search
          this.searching = true
          this.elements = []

          // Get Elements
          this.elements = await this.graphDataProvider.getTokenNetwork(this.selectedAddress, this.tokenLookupTable.get(this.selectedAddress.toLowerCase()))

          // Get Communities
          const nodeToCommunityMapping = await this.graphDataProvider.getNodeToCommunityMap(this.elements)
          const uniqueCommunityIds = [...new Set(nodeToCommunityMapping.values())]

          // Generate Community Classes
          for(var id of uniqueCommunityIds) {
            var color = Constants.StringToColor.next(id)
            if(color == null) {
              color = Constants.colors[id]
            }

            this.cy.style()
                .selector('node.c' + id)
                .style({
                  'background-color': color
                })
                .update()
          }

          // Set Elements
          this.cy.remove('')
          this.cy.add(this.elements)

          // Update Nodes
          var nodes = this.cy.nodes('')

          // Update Node Community Classes
          for(var node of nodes) {
            let nId = node.data('id')
            this.cy.$('#' + nId).addClass("c" + nodeToCommunityMapping.get(nId))
            if(this.searchedQueries.includes(nId)) {
              this.cy.$('#' + nId).addClass("target")
            }
            // Update node score
            this.cy.$('#' + nId).data("score", this.cy.$('#' + nId).incomers().length)
            // For all incomers, sum up the amount
            // Same for outgoing
            //
          }

          // Get max TX transaction count
          var maxEdgeTransactions = this.cy.edges().max(function(edge){
            return edge.data('transactions')
          });
          this.maxConnections = maxEdgeTransactions.value

          // Get max TX totalSum
          var maxEdgeTotalHumanreadableSum = this.cy.edges().max(function(edge){
            return edge.data('humanReadableTotalSum')
          });
          this.maxTotalSum = maxEdgeTotalHumanreadableSum.value

          // Get max score
          var maxNodeScore = this.cy.nodes().max(function(node){
            return node.data('score')
          });

          this.cy.style()
              .selector('node')
              .style({
                "width": "mapData(score, 0, " + maxNodeScore.value + ", 10, 80)",
                "height": "mapData(score, 0, " + maxNodeScore.value + ", 10, 80)",
              })
              .update()

          // Apply Filters...
          if(this.selectedFocus == Constants.RelationshipFocus) {
            // Filter out edges and nodes
            var filteredElements = []
            this.cy.filter(function(element, i){
              if(!element.isEdge()) {
                return false
              }
              let txs = element.data('transactions')
              let result = txs < this.connectionThreshold
              return result
            }.bind(this)).remove();

            this.cy.$('.transaction-focus').remove()

          } else if(this.selectedFocus == Constants.TransactionFocus) {
            // Filter out edges and nodes
            var filteredElements = []
            this.cy.filter(function(element, i){
              if(!element.isEdge()) {
                return false
              }
              let humanReadableTotalSum = element.data('humanReadableTotalSum')
              let result = humanReadableTotalSum < this.totalSumThreshold
              return result
            }.bind(this)).remove();

            this.cy.$('.relationship-focus').remove()

          } else if(this.selectedFocus == Constants.HybridFocus) {
            // Filter out edges and nodes
            var filteredElements = []
            this.cy.filter(function(element, i){
              if(!element.isEdge()) {
                return false
              }
              let txs = element.data('transactions')
              let result = txs < this.connectionThreshold
              return result
            }.bind(this)).remove();

            // Filter out edges and nodes
            var filteredElements = []
            this.cy.filter(function(element, i){
              if(!element.isEdge()) {
                return false
              }
              let humanReadableTotalSum = element.data('humanReadableTotalSum')
              let result = humanReadableTotalSum < this.totalSumThreshold
              return result
            }.bind(this)).remove();
          }

          // Remove orphaned nodes
          this.cy.filter(function(element, i){
            if(!element.isNode()) {
              return false
            }
            let hasEdges = element.connectedEdges().length <= 0
            return hasEdges
          }.bind(this)).remove();

          // Set up edge classes
          var dataMapProperty = 'transactions'
          var dataMapMaximum = maxEdgeTransactions.value
          var dataMapColorStart = Constants.RelationshipEdgeColorStart
          var dataMapColorEnd = Constants.RelationshipEdgeColorEnd
          var descriptionProperty = 'relationshipDescription'

          this.cy.style()
              .selector('edge.relationship-focus')
              .style({
                "width": "mapData(" + dataMapProperty + ", 0, " + dataMapMaximum + ", 1, 10)",
                "arrow-scale": "mapData(" + dataMapProperty + ", 0, " + dataMapMaximum + ", 0.5, 1.2)",
                "line-color": "mapData(" + dataMapProperty + ", 0, " + dataMapMaximum + ", " + dataMapColorStart + ", " + dataMapColorEnd + ")",
                'mid-target-arrow-color': "mapData(" + dataMapProperty + ", 0, " + dataMapMaximum  + ", " + dataMapColorStart + ", " + dataMapColorEnd + ")",
                'control-point-distances': "80"
              })
              .update()

          this.cy.style()
              .selector('edge.relationship-focus.showLabel')
              .style({
                "text-background-color": "mapData(" + dataMapProperty + ", 0, " + dataMapMaximum + ", " + dataMapColorStart + ", " + dataMapColorEnd + ")",
              })
              .update()

          dataMapProperty = 'humanReadableTotalSum'
          dataMapMaximum = maxEdgeTotalHumanreadableSum.value
          dataMapColorStart = Constants.TransactionEdgeColorStart
          dataMapColorEnd = Constants.TransactionEdgeColorEnd
          descriptionProperty = 'transactionDescription'

          this.cy.style()
              .selector('edge.transaction-focus')
              .style({
                "width": "mapData(" + dataMapProperty + ", 0, " + dataMapMaximum + ", 1, 10)",
                "arrow-scale": "mapData(" + dataMapProperty + ", 0, " + dataMapMaximum + ", 0.5, 1.2)",
                "line-color": "mapData(" + dataMapProperty + ", 0, " + dataMapMaximum + ", " + dataMapColorStart + ", " + dataMapColorEnd + ")",
                'mid-target-arrow-color': "mapData(" + dataMapProperty + ", 0, " + dataMapMaximum  + ", " + dataMapColorStart + ", " + dataMapColorEnd + ")",
              })
              .update()

          this.cy.style()
              .selector('edge.transaction-focus.showLabel')
              .style({
                "text-background-color": "mapData(" + dataMapProperty + ", 0, " + dataMapMaximum + ", " + dataMapColorStart + ", " + dataMapColorEnd + ")",
              })
              .update()

          // Run Layout
          this.cy.layout(this.getLayout()).run();
          this.cy.fit()

          let wi = this.cy.width()
          let he = this.cy.height()

          this.setupExportLabels()

          // Indicate finished
          this.searching = false
        }
      },
      setupExportLabels() {

        this.cy.$("#token-info").remove()
        this.cy.$("#date").remove()
        this.cy.$("#min").remove()
        this.cy.$("#max").remove()

        // Add Label Node
        this.cy.add(
            { group: 'nodes',
              data: { id: 'token-info', label: this.currentToken.name },
              classes: 'meta-info showLabel force-show'},
        )
        // Add Date Node
        let da = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric'})
        let ti = new Date().toLocaleTimeString('en-us')

        this.cy.add(
            { group: 'nodes',
              data: { id: 'date', label: da + " - " + ti},
              classes: 'meta-info force-show showLabel'},
        )

        // Add Min/Max Nodes
        this.cy.add(
            { group: 'nodes',
              data: { id: 'min', label: this.totalSumThresholdLabel},
              classes: 'meta-info force-show showLabel'},
        )

        this.cy.add(
            { group: 'nodes',
              data: { id: 'max', label: this.maxTotalSumLabel},
              classes: 'meta-info force-show showLabel'},
        )

        this.cy.style()
            .selector('#token-info')
            .style({
              "font-size": "100px",
              "text-valign": "center",
              "text-halign": "left",
              'border-width': '5px',
              "width": "20px",
              "height": "20px"
            })
            .update()

        this.cy.style()
            .selector('#date, #min, #max')
            .style({
              "font-size": "52px",
              "text-valign": "center",
              "text-halign": "left",
              'border-width': '5px',
              "width": "20px",
              "height": "20px"
            })
            .update()

        let top = 100
        this.cy.$("#token-info").position({x: -70, y: top})
        this.cy.$("#date").position({x: -70, y: top + 100})
        this.cy.$("#min").position({x: -70, y: top + 160})
        this.cy.$("#max").position({x: -70, y: top + 220})

      }
      ,
      setupCyGraph() {
        let cy = cytoscape({
          container: this.$refs.cyto,
          elements: [],
          style: Constants.cyStyle
        })

        this.cy = cy

        this.cy.on('mouseover', 'edge, node', function(e){
          var sel = e.target;
          sel.addClass('showLabel')
        }.bind(this))

        this.cy.on('click', 'edge, node', function(e){
          var sel = e.target;
          if(!sel.classes().includes("force-show")) {
            sel.addClass('force-show')
          } else {
            sel.removeClass('force-show')
            sel.removeClass('showLabel')
          }
        }.bind(this))

        this.cy.on('mouseout', 'edge, node', function(e){
          var sel = e.target;
          if(!sel.classes().includes("force-show")) {
            sel.removeClass('showLabel')
          }
        }.bind(this))

        // Open node info in a new window
        this.cy.on(
            "taphold",
            "node",
            function(event, orignalEvent) {
              let node = event.target;
              let url = "https://ethplorer.io/address/" + node.data().id;
              const type = node.data().type;
              window.open(url, "_blank", "minimizable=false").focus();
            }.bind(this))

      },
      toggleShowMenu() {
        this.showMenu = !this.showMenu
      },
      async exportPNG() {

        Promise.resolve(this.cy.png({ output: "blob-promise" })).then(
            (result) => {
              var image = new Image();
              image.src = URL.createObjectURL(result);
              window.open(image.src, "_blank");
            }
        )
      },
      setupFromURL() {
        const addressParam = this.$route.query.address;
        if(!!addressParam) {
          this.selectedAddress = addressParam;
          this.selectedAddress = this.selectedAddress.toLowerCase()
          this.searchFromScratch()
        }
      },
      updateLayout() {
        // Run Layout
        this.cy.layout(this.getLayout()).run();
        this.cy.fit()
        this.setupExportLabels()
      },
      getLayout() {
        if(this.selectedLayout == "Cose") {
          return Constants.coseLayout
        } else if(this.selectedLayout == "Concentric") {
          return Constants.concentricLayout
        } else if(this.selectedLayout == "Grid") {
          return Constants.gridLayout
        } else if(this.selectedLayout == "Circle") {
          return Constants.circleLayout
        } else if(this.selectedLayout == "Breadthfirst") {
          return Constants.breadthfirstLayout
        } else {
          return Constants.coseLayout
        }
      }
    },
    async mounted() {
      this.api = new API()
      this.graphDataProvider = new GraphDataProvider(this.api)

      // Setup dropdown list
      for(var token of Constants.AvailableTokens) {
        token.address = token.address.toLowerCase()
        this.tokens.push(token)
      }

      this.setupCyGraph()

      this.setupFromURL()
    },
    computed: {
      connectionThresholdLabel() {
        return "Minimum Relationship Strength:" + " " + this.connectionThreshold
      },
      maxConnectionsLabel() {
        return ""
      }
      ,
      maxTotalSumLabel() {
        this.selectedAddress = this.selectedAddress.toLowerCase()
        var token = this.tokenLookupTable.get(this.selectedAddress)

        var symbol = ""
        var rate = 0
        var currency = ""

        if(token != undefined) {
          symbol = token['symbol']
          rate = token['price']['rate']
          currency = token['price']['currency']

          if(rate != undefined) {
            let multiplicationWithRate = this.maxTotalSum * rate
            let humanReadableWithCurrency = multiplicationWithRate.toLocaleString("en-US", {
              style: "currency",
              currency: "USD"
            });
            return "Maximum: " + Intl.NumberFormat().format(this.maxTotalSum) + symbol + " (" + humanReadableWithCurrency + ")"
          } else {
            return "Maximum: " + Intl.NumberFormat().format(this.maxTotalSum) + symbol
          }
        } else {
          console.log("Didn't get full token info!!")
        }

        return "Maximum: " + Intl.NumberFormat().format(this.maxTotalSum) + symbol + " (" + humanReadableWithCurrency + ")"
      }
      ,
      totalSumThresholdLabel() {
        this.selectedAddress = this.selectedAddress.toLowerCase()
        var token = this.tokenLookupTable.get(this.selectedAddress)

        var symbol = ""
        var rate = 0
        var currency = ""

        if(token != undefined) {
          symbol = token['symbol']
          rate = token['price']['rate']
          currency = token['price']['currency']

          if(rate != undefined) {
            let multiplicationWithRate = this.totalSumThreshold * rate
            let humanReadableWithCurrency = multiplicationWithRate.toLocaleString("en-US", {
              style: "currency",
              currency: "USD"
            });
            return "Minimum: " + Intl.NumberFormat().format(this.totalSumThreshold) + symbol + " (" + humanReadableWithCurrency + ")"
          }
        } else {
          console.log("Didn't get full token info!!")
        }
        return "Minimum: " + Intl.NumberFormat().format(this.totalSumThreshold) + symbol
      },
      computedRelationshipEdgeColorEnd() {
        return Constants.RelationshipEdgeColorEnd
      },
      computedTransactionEdgeColorEnd() {
        return Constants.TransactionEdgeColorEnd
      }
    }
  })
